import { Text, TextField } from '@radix-ui/themes'
import { pack } from '@synqly/fun'
import { signIn as authenticate } from 'next-auth/react'
import Head from 'next/head'
import { useRouter } from 'next/router.js'
import { Title as DialogTitle } from '../../components/dialog'
import * as Form from '../../components/form'
import { Link } from '../../components/link'
import { useLocalStorage, useSessionStorage } from '../../hooks/use-storage'
import styles from '../auth.module.css'

export { SignInPage }

const failedLogon = {
  severity: Form.MessageSeverity.ERROR,
  message: 'Logon failed. Please try again.',
}

const Messages = {
  SessionRequired: {
    severity: Form.MessageSeverity.ERROR,
    message:
      'You were automatically logged out. Please sign back in to continue.',
  },
  AccountEnabled: {
    severity: Form.MessageSeverity.INFO,
    message:
      'Your account was successfully enabled. Please sign in to continue.',
  },
  'logon failed': failedLogon,
  'Not Found': failedLogon,
}

/**
 * @param {{
 *   organization?: {
 *     id: string
 *     name: string
 *     fullname: string
 *   }
 *   query: {
 *     callbackUrl: string
 *     error: string
 *     message: string
 *     email: string
 *   }
 *   routes: {
 *     signUp: string | false
 *     reset: string | false
 *   }
 *   redirectUrl: string
 * }} props
 */
function SignInPage({
  organization,
  query: { callbackUrl, error, message, email: initialEmail, organizationId },
  routes,
  redirectUrl,
  validation,
}) {
  const router = useRouter()

  const [orgId, setOrgId] = useLocalStorage(
    '/auth:{orgId}',
    organization?.name ?? organizationId,
  )
  const [email, setEmail] = useSessionStorage('/auth:{email}', initialEmail)

  return (
    <>
      <Head>
        <title>Sign in</title>
      </Head>

      <DialogTitle className={styles.heading}>
        {organization?.fullname}
      </DialogTitle>
      <Form.Root
        action={signIn}
        initialMessage={
          error ? (Messages[error] ?? failedLogon) : Messages[message]
        }
      >
        <Form.Field hidden={!!organization} name="organization" required>
          <Form.Label>
            <Text>Organization id</Text>
          </Form.Label>
          <Form.Control asChild>
            <TextField.Root
              autoComplete="off"
              onChange={({ target }) => setOrgId(target.value)}
              placeholder="00000000-0000-..."
              type={organization ? 'hidden' : 'text'}
              value={orgId ?? ''}
            />
          </Form.Control>
        </Form.Field>

        <Form.Field name="email" required>
          <Form.Label>
            <Text>E-mail</Text>
          </Form.Label>
          {...validation.email.messages}
          <Form.Control asChild>
            <TextField.Root
              {...validation.email.rules}
              autoComplete="username"
              onChange={({ target }) => setEmail(target.value)}
              placeholder="alice@company.com"
              type="email"
              value={email ?? ''}
            />
          </Form.Control>
        </Form.Field>

        <Form.Field name="password" required>
          <Form.Label>
            <Text>Password</Text>
          </Form.Label>
          {...validation.password.messages}
          <Form.Control asChild>
            <TextField.Root
              {...validation.password.rules}
              autoComplete="current-password"
              placeholder="••••••••"
              type="password"
            />
          </Form.Control>
        </Form.Field>

        <Form.Submit mt="5">Sign in</Form.Submit>

        {!!routes.reset && (
          <Text align="center" size="1">
            <Link
              href={{
                pathname: routes.reset,
                query: pack({
                  organizationId: orgId,
                  name: email,
                }),
              }}
            >
              Forgot your password?
            </Link>
          </Text>
        )}

        {!!routes.signUp && (
          <Text align="center" mt="2" size="1">
            Not registered? <Link href={routes.signUp}>Create account</Link>
          </Text>
        )}
      </Form.Root>
    </>
  )

  async function signIn(formData) {
    const { organization, email, password } = Object.fromEntries(formData)

    const signIn = await authenticate('credentials', {
      redirect: false,
      callbackUrl,
      organization,
      username: email,
      password,
    })

    if (signIn.ok) {
      await router.replace(redirectUrl ?? signIn.url ?? '/')
    } else {
      throw Messages[signIn.error] ?? failedLogon
    }
  }
}
